.centrado {
    text-align: center;
}

.screen {
    background: #373737;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 70px;
    color: #76ff03;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 1em;
    align-content: center;
}

.screen p {
    font-size: 4em;
}