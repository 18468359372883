.widthCobrar {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-secondary:hover {
    color: #000;
    background-color: #5a6268 !important;
    border-color: #545b62 !important;
}

.fade-in-image {
    animation: fadeIn 0.4s;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    -ms-animation: fadeIn 0.4s;
}

.centrado {
    text-align: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}